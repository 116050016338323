import React from 'react';

import { Avatar, Button, Dropdown, MenuProps, Space, TableColumnsType, Tag, Tooltip } from 'antd';

import { EditOutlined } from '@ant-design/icons';
import { AuthRoute, AuthRoutes } from 'components/_Common/_Routes/Routes';
import { renderStatusMaintenance } from 'components/Overview/PendingMaintenancesCard';
import { Authorization, FEATURE } from 'lib/helpers/Authorization.helper';
import { getIssueDerivedStatus, getIssueDerivedStatusTagProps } from 'lib/helpers/Issue.helper';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';
import { ScheduleIssue, ScheduleMaintenance, useSchedule } from 'lib/providers/ScheduleContextProvider';

type IssueActionsCellProps = { issue: ScheduleIssue };
type MaintenanceActionsCellProps = { maintenance: ScheduleMaintenance };

const wl = makeWhiteLabel();

/** @factory */
const makeUrlWithParameter = (route: AuthRoute, parameter: string, value: string): string => {
    const url = `${route}?${parameter}=${value}`;

    return url;
};

const IssueActionsCell = ({ issue }: IssueActionsCellProps) => {
    const { isOverviewModule } = useSchedule();

    const route = isOverviewModule
        ? AuthRoutes.overviewIssue
        : AuthRoutes.issue;

    const url = makeUrlWithParameter(route, 'detail', issue.id.toString());

    return (
        <Button href={url} target="_blank">
            Detalhes
        </Button>
    );
};

const MaintenanceActionsCell = ({ maintenance }: MaintenanceActionsCellProps) => {
    const { isOverviewModule } = useSchedule();

    const route = isOverviewModule
        ? AuthRoutes.overviewMaintenance
        : AuthRoutes.maintenance;

    if (!Authorization.hasAccess(FEATURE['MAINTENANCE::WRITE']) || maintenance.isFinished) {
        const url = makeUrlWithParameter(route, 'detail', maintenance.id.toString());

        return (
            <Button href={url} target="_blank">
                Detalhes
            </Button>
        );
    }

    const items: NonNullable<MenuProps['items']> = [{
        key: 'edit',
        label: 'Editar',
        icon: <EditOutlined />,
        onClick: () => {
            const url = makeUrlWithParameter(
                route,
                'edit',
                maintenance.id.toString()
            );

            window.open(url);
        }
    }];

    const url = makeUrlWithParameter(route, 'finish', maintenance.id.toString());

    return (
        <Dropdown.Button
            trigger={['click']}
            menu={{ items }}
            onClick={() => window.open(url)}
        >
            Finalizar
        </Dropdown.Button>
    );
};

export const ISSUES_COLUMNS: TableColumnsType<ScheduleIssue> = [
    {
        title: 'Condomínio',
        dataIndex: ['client', 'name'],
        key: 'clientName'
    },

    {
        title: 'Status',
        dataIndex: 'status',
        render: (status, issue) => {
            const derivedStatus = getIssueDerivedStatus(status, issue.deadline);
            const props = getIssueDerivedStatusTagProps(derivedStatus);

            return <Tag {...props} />;
        },
        width: 120
    },

    {
        title: 'Categoria',
        dataIndex: 'issue_type_id',
        render: (_, record) => record.issue_type.name
    },

    {
        title: 'Assunto',
        dataIndex: 'subject'
    },

    {
        title: 'Responsável',
        dataIndex: 'user_responsible_id',
        render: (_, { user_responsible }) => {
            if (!user_responsible)
                return '-';

            const [firstName, lastName] = user_responsible.name.split(' ');

            return (
                <Tooltip title={user_responsible.name} placement="top">
                    <Space>
                        <Avatar
                            src={user_responsible.picture}
                            style={{ backgroundColor: wl.colorPrimary }}
                        >
                            {firstName[0]}
                            {lastName ? lastName[0] : ''}
                        </Avatar>

                        {firstName}
                    </Space>
                </Tooltip>
            );
        },
    },

    {
        key: 'actions',
        render: (_, issue) => <IssueActionsCell issue={issue} />,
    },
];

export const MAINTENANCES_COLUMNS: TableColumnsType<ScheduleMaintenance> = [
    {
        title: 'Condomínio',
        dataIndex: ['client', 'name'],
        key: 'clientName'
    },

    {
        title: 'Status',
        key: 'status',
        render: (_, maintenance) => renderStatusMaintenance(maintenance),
        width: 120,
    },

    {
        title: 'Categoria',
        dataIndex: 'maintenance_type',
        render: (type, { description }) => type?.name ?? description,
    },

    {
        title: 'Fornecedor',
        dataIndex: 'supplier',
        render: supplier => supplier?.name ?? '-'
    },

    {
        key: 'actions',
        render: (_, maintenance) => <MaintenanceActionsCell maintenance={maintenance} />,
    },
];