type Hostname =
    | 'soluciona.gcondo.com.br'
    | 'aap.gcondo.com.br'
    | 'sindigest.gcondo.com.br'
    | 'sindigest.com.br'
    | 'najasaid.sindigest.com.br'
    | 'app.sindigest.com.br'
    | 'www.sindigest.com.br'
    | 'localhost'

type WhiteLabel = {
    name: string,
    logo: string,
    icon: string,
    icon_mini?: string,
    colorPrimary: string,
    colorSecondary?: string,
    siderBg: string,
    triggerBg: string,
    darkItemBg: string,
    contract: 'gcondo' | 'sindigest',
    favicon: string,
    defaultUrl: string,
    calendar?: {
        itemActiveBg: string,
    },
};

const DEFAULT_WHITE_LABEL: WhiteLabel = {
    name: 'Gcondo',
    logo: '/assets/img/clients/default/logo.png',
    icon: '/assets/img/clients/default/logo_mini.png',
    colorPrimary: '#004AAD',
    colorSecondary: '#50C961',
    siderBg: '#0A0227',
    triggerBg: '#7D8597',
    darkItemBg: '#0A0227',
    contract: 'gcondo',
    favicon: './gcondo.ico',
    defaultUrl: 'https://app.gcondo.com.br',
    calendar: {
        itemActiveBg: '#C8DFFF',
    }
};

const SINDIGEST_WHITE_LABEL: WhiteLabel = {
    name: 'SindiGest',
    logo: '/assets/img/clients/sindigest/logo.png',
    icon: '/assets/img/clients/sindigest/icon.png',
    icon_mini: '/assets/img/clients/sindigest/logo_mini.png',
    colorPrimary: '#000000',
    colorSecondary: '#bf6837',
    siderBg: '#000000',
    triggerBg: '#7D8597',
    darkItemBg: '#000000',
    contract: 'sindigest',
    favicon: './sindigest.ico',
    defaultUrl: 'https://sindigest.com.br',
    calendar: {
        itemActiveBg: '#7D8597',
    }
} as const;

const LOCALHOST_WHITE_LABEL: WhiteLabel = {
    ...DEFAULT_WHITE_LABEL,
    defaultUrl: 'http://localhost:3000',
};

const WHITE_LABELS: Record<Hostname, WhiteLabel> = {
    'soluciona.gcondo.com.br': {
        name: 'Soluciona',
        logo: '/assets/img/clients/soluciona/logo.png',
        icon: '/assets/img/clients/soluciona/icon.png',
        colorPrimary: '#5d0033',
        colorSecondary: '#744b6b',
        siderBg: '#5d0033',
        triggerBg: '#978aaf',
        darkItemBg: '#5d0033',
        contract: 'gcondo',
        favicon: './gcondo.ico',
        defaultUrl: 'https://app.gcondo.com.br'
    },
    'aap.gcondo.com.br': {
        name: 'AAP',
        logo: '/assets/img/clients/aap/logo.png',
        icon: '/assets/img/clients/aap/icon.png',
        colorPrimary: '#000000',
        colorSecondary: '#afb1b7',
        siderBg: '#000000',
        triggerBg: '#7D8597',
        darkItemBg: '#000000',
        contract: 'gcondo',
        favicon: './gcondo.ico',
        defaultUrl: 'https://app.gcondo.com.br'
    },
    'najasaid.sindigest.com.br': {
        name: 'Najasaid',
        logo: '/assets/img/clients/najasaid/logo.jpg',
        icon: '/assets/img/clients/najasaid/icon.jpg',
        colorPrimary: '#041d40',
        colorSecondary: '#fec601',
        siderBg: '#041d40',
        triggerBg: '#041d40',
        darkItemBg: '#041d40',
        contract: 'sindigest',
        favicon: './sindigest.ico',
        defaultUrl: 'https://najasaid.sindigest.com.br'
    },
    'sindigest.gcondo.com.br': SINDIGEST_WHITE_LABEL,
    'sindigest.com.br': SINDIGEST_WHITE_LABEL,
    'app.sindigest.com.br': SINDIGEST_WHITE_LABEL,
    'www.sindigest.com.br': SINDIGEST_WHITE_LABEL,
    'localhost': LOCALHOST_WHITE_LABEL,
} as const;

export function makeWhiteLabel(hostname: Hostname = document.location.hostname as Hostname): WhiteLabel {    
    const whiteLabel = WHITE_LABELS[hostname] ?? DEFAULT_WHITE_LABEL;

    return whiteLabel;
}